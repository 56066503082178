import Papa from "papaparse";
import React, { useState, useEffect } from "react";
import "./bulk-room-create.scss";

import ContactApi from "../../../../../../api/contact-api";
import ThreadApi from "../../../../../../api/thread-api";
import ContactHelper from "../../../../../../entities/contact/contact-helper";
import { EventEmitter } from "../../../../../../shared/utils/event-emitter";
import Events from "../../../../../../const/events";

const BulkRoomCreateModal = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const processContact = async (name, phone) => {
    try {
      // First check if contact exists
      const { data: checkResult } =
        await ContactApi.private.checkIfContactExists({
          phone: `${phone}`,
        });

      // If contact exists (200 response), return the contact ID directly
      if (!checkResult.error && checkResult.data?._id) {
        return checkResult.data._id;
      }

      // If contact doesn't exist (404 response), create a new one
      if (checkResult.error && checkResult.statusCode === 404) {
        const { contactId } = await ContactHelper.createNewContact({
          name,
          phone,
        });
        if (contactId) {
          return contactId;
        }
      }

      // If we get here, something unexpected happened
      throw new Error(`Failed to process contact: ${checkResult.message}`);
    } catch (error) {
      console.error("Error processing contact:", error);
      throw error;
    }
  };

  const getFirstName = (fullName) => {
    return fullName.split(" ")[0];
  };

  const createRoom = async (
    contact1Id,
    contact2Id,
    contact1Name,
    contact2Name
  ) => {
    try {
      const roomTitle = `${getFirstName(contact1Name)}+${getFirstName(
        contact2Name
      )}`;

      const response = await ThreadApi.private.createThread({
        type: "room",
        title: roomTitle,
        _users: [],
        _contacts: [contact1Id, contact2Id],
      });

      return { success: true, data: response?.data };
    } catch (error) {
      if (
        error.response?.data?.message ===
        "one or more contacts already have an active thread"
      ) {
        return { success: false, message: "EXISTS" };
      }
      throw error;
    }
  };

  const processCSV = async () => {
    if (!selectedFile) return;
    setIsProcessing(true);
  
    try {
      const csvData = await new Promise((resolve, reject) => {
        Papa.parse(selectedFile, {
          complete: (results) => resolve(results.data),
          error: (error) => reject(error),
          header: true,
        });
      });
  
      const results = [];
      for (const row of csvData) {
        try {
          // Process contacts sequentially
          const contact1Id = await processContact(
            row["Contact1 Name"],
            row["Contact1 Mobile"]
          );
          
          const contact2Id = await processContact(
            row["Contact2 Name"],
            row["Contact2 Mobile"]
          );
  
          const roomResult = await createRoom(
            contact1Id,
            contact2Id,
            row["Contact1 Name"],
            row["Contact2 Name"]
          );
  
          results.push({
            contact1: {
              name: row["Contact1 Name"],
              mobile: row["Contact1 Mobile"],
            },
            contact2: {
              name: row["Contact2 Name"],
              mobile: row["Contact2 Mobile"],
            },
            status: {
              success: roomResult.success,
              message: roomResult.success ? "CREATED" : roomResult.message,
            },
          });
  
          // Update UI after each row is processed
          setProcessedData([...results]);
        } catch (error) {
          results.push({
            contact1: {
              name: row["Contact1 Name"],
              mobile: row["Contact1 Mobile"],
            },
            contact2: {
              name: row["Contact2 Name"],
              mobile: row["Contact2 Mobile"],
            },
            status: {
              success: false,
              message: "INVALID",
            },
          });
          setProcessedData([...results]);
        }
      }
  
      setShowResults(true);
    } catch (error) {
      console.error("Error processing CSV:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDownloadSample = () => {
    const sampleData =
      "Contact1 Name,Contact1 Mobile,Contact2 Name,Contact2 Mobile\nJohn Doe,1234567890,Jane Doe,9876543210";
    const blob = new Blob([sampleData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample_contacts.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const _registerEvents = () => {
      EventEmitter.subscribe(Events.SHOW_CSV_ROOM_IMPORT_POPUP, () => {
        setPopupVisible(true);
      });
      EventEmitter.subscribe(Events.HIDE_CSV_ROOM_IMPORT_POPUP, () => {
        setPopupVisible(false);
      });
    };
    const _unregisterEvents = () => {
      EventEmitter.cancelAll(Events.SHOW_CSV_ROOM_IMPORT_POPUP);
      EventEmitter.cancelAll(Events.HIDE_CSV_ROOM_IMPORT_POPUP);
    };
    _registerEvents();
    return () => {
      _unregisterEvents();
    };
  }, []);

  if (!popupVisible) {
    return null;
  }

  console.log('selectedFile :>> ', selectedFile);

  return (
    <div className="modal-wrapper">
      <div
        className="modal fade show"
        id="newCSVFileUploadModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="newCSVFileUploadModalTitle"
        aria-hidden={false}
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header py-3">
              <h6 className="modal-title m-0" id="newCSVFileUploadModalTitle">
                Upload CSV file
              </h6>
              {showResults ? (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary me-2"
                  onClick={() => {
                    setShowResults(false);
                    setSelectedFile(null);
                    setProcessedData([]);
                    setPopupVisible(false);
                  }}
                >
                  Close
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setSelectedFile(null);
                    setPopupVisible(false);
                  }}
                ></button>
              )}
            </div>

            <div className="modal-body">
              <div className="modalInner modalLeft">
                <div className="sampleCSVWrapper">
                  <span>Sample CSV</span>
                  <div
                    className="btn btn-success btn-sm"
                    onClick={handleDownloadSample}
                  >
                    <i className="fa fa-download me-1"></i> Download
                  </div>
                </div>

                <div className="selectCSVFileWrapper">
                  <div className="custom-file csvUpload">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                      accept=".csv"
                      onChange={handleFileUpload}
                      style={{ opacity: 0, position: "absolute" }} // Add this style
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }} // Add this style
                    >
                      {selectedFile?.name || "Upload CSV file"}
                    </label>
                  </div>
                </div>
              </div>

              {showResults && (
                <div className="modalInner modalRight">
                  <div className="tableWrapper">
                    <table className="table table-responsive">
                      <colgroup>
                        <col width="200px" />
                        <col width="200px" />
                        <col width="200px" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Phone 1</th>
                          <th>Phone 2</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {processedData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.contact1.mobile}
                            {" "}({row.contact1.name})
                            </td>
                            <td>{row.contact2.mobile}
                            {" "}({row.contact2.name})
                            </td>
                            <td>
                              <div className="p-0">
                                {row.status?.success ? (
                                  <span className="badge bg-success">
                                    ✅ Room Created
                                  </span>
                                ) : row.status?.message === "EXISTS" ? (
                                  <span className="badge border border-blue text-blue">
                                    🔴 Already in a room
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    ⚠️ Invalid number
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer d-flex align-items-center justify-content-between">
              {!showResults && (
                <>
                  <button
                    type="button"
                    className="btn btn-soft-danger btn-sm"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setSelectedFile(null);
                      setPopupVisible(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    disabled={!selectedFile}
                    onClick={processCSV}
                  >
                    {isProcessing ? "Processing..." : "Submit"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkRoomCreateModal;
