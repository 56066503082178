import React, { useEffect } from "react";
import "./dashboard-layout.scss";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AuthHelper from "../../../features/authentication/helpers/auth-helper/auth-helper";
import QRViewer from "../qr-viewer/qr-viewer";
import BulkRoomCreateModal from "../../../features/manage-contacts/pages/manage-contacts-page/components/bulk-room-create/bulk-room-create";

const DashboardLayout = ({ children }) => {
  const { userInfo } = useSelector((state) => state.userStore);
  const [canManageUsers, setCanManageUsers] = React.useState(false);

  const location = useLocation();

  const _logout = (e) => {
    e.preventDefault();
    AuthHelper.logout();
  };

  useEffect(() => {
    if (userInfo?.role === "admin") {
      setCanManageUsers(true);
    }
  }, [userInfo]);

  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <div className="left-sidebar">
        {/* LOGO */}
        <div className="brand">
          <a href="index.html" className="logo">
            <span>
              <img
                src="assets/images/small_icon.png"
                alt="logo-small"
                className="logo-sm"
              />
            </span>
          </a>
        </div>
        {/* <div className="sidebar-user-pro media border-end">
          <div className="position-relative mx-auto">
            <img
              src="assets/images/profile-avatar.png"
              alt="user"
              className="rounded-circle thumb-md"
            />
            <span className="online-icon position-absolute end-0">
              <i className="mdi mdi-record text-success" />
            </span>
          </div>
          <div className="media-body ms-2 user-detail align-self-center">
            <h5 className="font-16 m-0 fw-bold">{userInfo?.email}</h5>
            <p className="opacity-50 mb-0">Role: Manager</p>
          </div>
        </div> */}
        <div className="menu-content h-100" data-simplebar>
          <div className="menu-body navbar-vertical">
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse"
            >
              <ul
                className="navbar-nav tab-pane active"
                id="Main"
                role="tabpanel"
              >
                <Link
                  className={`nav-link ${
                    location.pathname === "/whatsapp-chats" ? "active" : ""
                  }`}
                  to="/whatsapp-chats"
                  title="Whatsapp Business chats"
                >
                  <i className="fab fa-whatsapp menu-icon" />{" "}
                  <span>Whatsapp Business chats</span>
                </Link>
                {canManageUsers && (
                  <Link
                    className={`nav-link ${
                      location.pathname === "/manage-users" ? "active" : ""
                    }`}
                    to="/manage-users"
                  >
                    <i className="ti ti-users menu-icon" />{" "}
                    <span>User list</span>
                  </Link>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Top Bar */}
      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown">
              <a
                className="nav-link dropdown-toggle nav-user"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <img
                    src="assets/images/favicon.png"
                    alt="profile-user"
                    className="rounded-circle me-2 thumb-sm"
                    style={{
                      border: "1px solid gray",
                      borderRadius: "50%",
                    }}
                  />
                  <div>
                    <small className="d-none d-md-block font-11">
                      Team RELAi
                    </small>
                    <span className="d-none d-md-block fw-semibold font-12">
                      {userInfo?.email} <i className="mdi mdi-chevron-down" />
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="dropdown-divider mb-0" />
                <a className="dropdown-item" href="#" onClick={_logout}>
                  <i className="ti ti-power font-16 me-1 align-text-bottom" />{" "}
                  Logout
                </a>
              </div>
            </li>
          </ul>
          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              {/* <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
              >
                <i className="ti ti-menu-2" />
              </button> */}
            </li>
          </ul>
        </nav>
      </div>

      {/* Page Content */}
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid h-100">{children}</div>
        </div>

        {/* Footer */}
        {/* <footer className="footer text-center text-sm-start">
          © RELAi{" "}
          <span className="text-muted d-none d-sm-inline-block float-end">
            Crafted with <i className="mdi mdi-heart text-danger" /> by RELAi
          </span>
        </footer> */}
      </div>
      <QRViewer />
      
    </div>
  );
};

export default DashboardLayout;
