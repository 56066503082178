// contact-api.js
import { ProtectedHttpClient, PublicHttpClient } from "../shared/clients/http-client";
const ContactApi = {
  private: {
    getContacts: () => ProtectedHttpClient.post("/contacts?page=1&limit=100"), // Requires token
    createContact: (data) => ProtectedHttpClient.post("/contact", data), // Requires token
    getRoomLink: (contactId) => ProtectedHttpClient.get(`/contact/${contactId}/generate-room-link`), // Requires token
    checkIfContactExists: (payload) => ProtectedHttpClient.post(`/contact/check`, payload), // Requires token
  },
};

export default ContactApi;
