import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { UserStoreReducer } from "./user-store";
import { ThreadStoreReducer } from "./thread-store";
import { MessageStoreReducer } from "./message-store";
import { TemplateStoreReducer } from "./template-store";
import { ContactsStoreReducer } from "./contacts-store";

const persistConfig = {
  key: "relai-v1",
  storage,
  keyPrefix: "",
  stateReconciler: hardSet,
  blacklist: ["threadStore", "messageStore", "templateStore"],
};

const pReducer = persistReducer(persistConfig, UserStoreReducer);

const rootReducer = combineReducers({
  userStore: pReducer,
  threadStore: ThreadStoreReducer,
  messageStore: MessageStoreReducer,
  templateStore: TemplateStoreReducer,
  contactsStore: ContactsStoreReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
