import ApiCalls from "../../api";
import { ContactStoreActions } from "../../redux/contacts-store";
import { store } from "../../redux/store";

const ContactHelper = {
  createNewContact: async ({ name, phone }) => {
    const response = await ApiCalls.contact.private.createContact({
      of: "unknown",
      platforms: [
        {
          referenceId: `whatsapp:${phone}`,
          on: "whatsapp",
        },
      ],
      name,
      phone,
    });
    return {
      contactId: response?.data?.data?._id || null,
    };
  },
  fetchAllContacts: async () => {
    const {data} = await ApiCalls.contact.private.getContacts();
    if (data?.data?.docs) {
      store.dispatch(ContactStoreActions.setContacts(data.data.docs));
    }

  },
};

export default ContactHelper;
