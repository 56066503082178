import React, { useState, useEffect } from "react";
import "./manage-contacts-page.scss";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ContactHelper from "../../../../entities/contact/contact-helper";
import { useSelector } from "react-redux";

const ManageContactsPage = () => {
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { contacts } = useSelector((state) => state.contactsStore);

  // Define columns
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Date added",
      selector: (row) => row.dateAdded,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleSendMessage(row)}
        >
          Send Message
        </button>
      ),
    },
  ];

  // Transform contacts data into the format needed for the table
  useEffect(() => {
    if (contacts) {
      const transformedContacts = contacts.map((contact) => ({
        id: contact._id,
        name: contact.name || "Unknown",
        phone: contact.phone || "N/A",
        dateAdded: new Date(contact.createdAt).toLocaleDateString(),
      }));
      setFilteredContacts(transformedContacts);
    }
  }, [contacts]);

  // Handle search/filter
  const handleSearch = (e) => {
    const text = e.target.value.toLowerCase();
    setSearchText(text);

    const filtered = contacts
      .filter(
        (contact) =>
          (contact.name && contact.name.toLowerCase().includes(text)) ||
          (contact.phone && contact.phone.toLowerCase().includes(text))
      )
      .map((contact) => ({
        id: contact._id,
        name: contact.name || "Unknown",
        phone: contact.phone || "N/A",
        dateAdded: new Date(contact.createdAt).toLocaleDateString(),
      }));

    setFilteredContacts(filtered);
  };

  // Handle "Send Message" button click
  const handleSendMessage = (contact) => {
    // Here you can integrate the message-sending logic
    alert(`Message sent to ${contact.name} at ${contact.phone}`);
    // Example: Trigger ContactHelper or API call
    // ContactHelper.sendMessage(contact.id);
  };

  useEffect(() => {
    ContactHelper.fetchAllContacts();
  }, []);

  return (
    <>
      <div className="manage-contacts-page">
        <div className="pageTitle">
          <h3>Manage contacts</h3>

          <div className="right">
            <span
              className="btn btn-dark btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#addNewContactModal"
            >
              + Add contact
            </span>
          </div>
        </div>

        <div className="contactTableWrapper">
          <input
            type="text"
            placeholder="Search contacts..."
            className="form-control mb-3"
            value={searchText}
            onChange={handleSearch}
          />

          <DataTableExtensions
            columns={columns}
            data={filteredContacts}
            print={false}
            export={false}
            filterPlaceholder={"Enter search text"}
          >
            <DataTable columns={columns} data={filteredContacts} pagination />
          </DataTableExtensions>
        </div>

        <div className="modal" id="addNewContactModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add new contact</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mb-1"
                  >
                    Contact name
                  </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label mb-1"
                  >
                    Phone number
                  </label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save contact
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageContactsPage;
