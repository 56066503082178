const Events = {
  SHOW_CREATE_THREAD_POPUP: "show-create-thread-popup",
  SHOW_TWILIO_SANDBOX_WARNING: "show-twilio-sandbox-warning",
  HIDE_CREATE_THREAD_POPUP: "hide-create-thread-popup",
  HIDE_TWILIO_SANDBOX_WARNING: "hide-twilio-sandbox-warning",
  SHOW_QR_CODE_POPUP: "show-qr-code-popup",
  HIDE_QR_CODE_POPUP: "hide-qr-code-popup",
  SHOW_CSV_ROOM_IMPORT_POPUP: "show-csv-room-import-popup",
  HIDE_CSV_ROOM_IMPORT_POPUP: "hide-csv-room-import-popup",
};

export default Events;
