import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoaderActive: false,
  contacts: [],
};

const contactsSlice = createSlice({
  name: "contactsStore",
  initialState,
  reducers: {
    setLoaderActive: (state, action) => {
      state.isLoaderActive = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

const { setLoaderActive, setContacts } = contactsSlice.actions;

export const ContactStoreActions = {
  setLoaderActive,
  setContacts,
};

export const ContactsStoreReducer = contactsSlice.reducer;
