// routes.js
import LoginPage from "../features/authentication/pages/login-page/login-page";
import ChatPage from "../features/chat/pages/chat-page/chat-page";
import ManageContactsPage from "../features/manage-contacts/pages/manage-contacts-page/manage-contacts-page";
import ManageUsersPage from "../features/manage-users/pages/manage-users-page/manage-users";

const routes = [
  // Public Routes
  {
    path: "/login",
    component: LoginPage,
    isProtected: false,
  },
  // {
  //   path: "/forgot-password",
  //   component: ForgotPassword,
  //   isProtected: false,
  // },

  // Protected Routes (All will be inside DashboardLayout)
  {
    path: "/manage-users",
    component: ManageUsersPage,
    isProtected: true,
  },
  {
    path: "/manage-contacts",
    component: ManageContactsPage,
    isProtected: true,
  },
  {
    path: "/whatsapp-chats",
    component: ChatPage,
    isProtected: true,
  },

  // Fallback Route (404)
  {
    path: "*",
    component: LoginPage,
    isProtected: false,
  },
];

export default routes;
